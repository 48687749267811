export const OPEN_DRIVERS_GROUP_WITH_MESSAGE = 'CHAT/DATA_FOR_OPEN_DRIVERS_GROUP_WITH_MESSAGE_RECEIVED' as const;
export const UNREAD_DRIVERS_GROUP_MESSAGES_RECEIVED = 'CHAT/UNREAD_DRIVERS_GROUP_MESSAGES_RECEIVED' as const;
export const UNREAD_DRIVERS_GROUPS_INFO_RECEIVED = 'CHAT/UNREAD_DRIVERS_GROUPS_INFO_RECEIVED' as const;
export const DRIVERS_GROUP_ALL_MESSAGES_RECEIVED = 'CHAT/DRIVERS_GROUP_ALL_MESSAGES_RECEIVED' as const;
export const TRUCK_FOR_DRIVERS_GROUP_RECEIVED = 'CHAT/TRUCK_FOR_DRIVERS_GROUP_RECEIVED' as const;
export const ATTACHMENTS_TO_MESSAGE_RECEIVED = 'CHAT/ATTACHMENTS_TO_MESSAGE_RECEIVED' as const;
export const ATTACHMENTS_TO_MESSAGE_CLEARED = 'CHAT/ATTACHMENTS_TO_MESSAGE_CLEARED' as const;
export const MISSING_TRUCK_NUMBERS_RECEIVED = 'CHAT/MISSING_TRUCK_NUMBERS_RECEIVED' as const;
export const ONLY_PAGINATION_TOTAL_RECEIVED = 'CHAT/ONLY_PAGINATION_TOTAL_RECEIVED' as const;
export const MORE_MESSAGES_RECEIVED = 'CHAT/DRIVERS_GROUPS_MORE_MESSAGES_RECEIVED' as const;
export const INIT_MESSAGES_RECEIVED = 'CHAT/DRIVERS_GROUPS_INIT_MESSAGES_RECEIVED' as const;
export const MORE_DRIVERS_GROUPS_RECEIVED = 'CHAT/MORE_DRIVERS_GROUPS_RECEIVED' as const;
export const OPEN_DRIVERS_GROUP = 'CHAT/DATA_FOR_OPEN_DRIVERS_GROUP_RECEIVED' as const;
export const SET_ATTACHMENTS_UPLOADING = 'CHAT/SET_ATTACHMENTS_UPLOADING' as const;
export const DISPATCHER_DATA_RECEIVED = 'CHAT/DISPATCHER_DATA_RECEIVED' as const;
export const SEARCHED_DRIVERS_CLEARED = 'CHAT/SEARCHED_DRIVERS_CLEARED' as const;
export const DRIVERS_GROUPS_RECEIVED = 'CHAT/DRIVERS_GROUPS_RECEIVED' as const;
export const SEARCH_PARAMS_RECEIVED = 'CHAT/SEARCH_PARAMS_RECEIVED' as const;
export const PAGINATION_RECEIVED = 'CHAT/PAGINATION_RECEIVED' as const;
export const LEAVE_DRIVERS_GROUP = 'CHAT/LEAVE_DRIVERS_GROUP' as const;
export const SET_MESSAGE_STATUS = 'CHAT/SET_MESSAGE_STATUS' as const;
export const TOGGLE_CHAT_WIDGET = 'CHAT/TOGGLE_CHAT_WIDGET' as const;

export const MARK_AS_READ_BY_EVERY_DISPATCHER = 'CHAT/MARK_AS_READ_BY_EVERY_DISPATCHER' as const;
export const MARK_AS_READ_BY_DISPATCHER = 'CHAT/MARK_AS_READ_BY_DISPATCHER' as const;
