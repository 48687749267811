import React from 'react';
import {FormControl, FormGroup} from 'react-bootstrap';
import classNames from 'classnames';
import {WrappedFieldProps} from 'redux-form';

import useChatActions from 'widgets/Chat/hooks/useChatActions';

import Button from 'components/ui/Buttons/Button';
import getValidationState from 'components/ui/Form/getValidationState';
import OverlayTriggerCustom from 'components/ui/Form/OverlayTriggerCustom';

import styles from './styles.module.scss';

type OwnProps = {
    input: WrappedFieldProps['input'];
    meta: WrappedFieldProps['meta'];
    isClearable?: boolean;
    placeholder?: string;
    autoFocus?: boolean;
    maxLength?: number;
    searching: boolean;
};

const DriverSearchInput: React.FC<OwnProps> = (props) => {
    const {isClearable = true, autoFocus = false, placeholder = '', maxLength, input, meta, searching} = props;

    const {clearSearchedDrivers} = useChatActions();

    const isValid = getValidationState(meta);

    const clearValue = () => {
        input.onChange('');
        clearSearchedDrivers();
    };

    const inputJSX = (
        <FormControl
            disabled={meta.asyncValidating || searching}
            placeholder={placeholder}
            autoFocus={autoFocus}
            maxLength={maxLength}
            autoComplete="off"
            {...props.input}
        />
    );

    return (
        <FormGroup className={classNames(styles.wrap, {'has-feedback has-error': !isValid})}>
            <OverlayTriggerCustom inputMeta={meta}>
                {inputJSX}

                <button className={styles.button__search} type="submit">
                    {!searching ? <i className="fa fa-search" aria-hidden="true" /> : null}
                    {searching ? <i className={classNames('ml5 fa fa-refresh fa-spin')} /> : null}
                </button>
            </OverlayTriggerCustom>

            {isClearable && input.value && !searching ? (
                <div className={styles.button__clear}>
                    <Button buttonIcon="times" buttonSize="icon" onClick={clearValue} />
                </div>
            ) : null}
        </FormGroup>
    );
};

export default DriverSearchInput;
