import React, {useEffect, useRef} from 'react';
import isString from 'lodash/isString';

import {handleKeyDown} from 'utils/form/handleKeyDown';

const Button = (props) => {
    const {
        children,
        colorTheme = 'grey',
        buttonSize = 'medium',
        buttonIcon,
        className = '',
        defaultFocus = false,
        disabled,
        ...rest
    } = props;

    const btnRef = useRef(null);
    useEffect(() => {
        if (defaultFocus && btnRef.current) {
            btnRef.current.focus();
        }
    }, []);

    if (buttonIcon) {
        const btnIcon = isString(buttonIcon) ? <i className={`fa fa-${buttonIcon}`} aria-hidden="true" /> : buttonIcon;

        return (
            <button
                disabled={disabled}
                ref={btnRef}
                type="button"
                className={`button button-with-icon button-${colorTheme} button-${buttonSize} ${className}`}
                onKeyDown={handleKeyDown}
                {...rest}
            >
                {btnIcon}
                {children}
            </button>
        );
    }
    return (
        <button
            disabled={disabled}
            ref={btnRef}
            type="button"
            className={`button button-${colorTheme} button-${buttonSize} ${className}`}
            onKeyDown={handleKeyDown}
            {...rest}
        >
            {children}
        </button>
    );
};

export default Button;
