import {AppState} from 'store';

import {ChatState} from '../reducers';

export const checkIsMessageStatusSuccess = (state: AppState): boolean => state.chat.messageStatus === 'success';
export const checkIsMessageStatusSending = (state: AppState): boolean => state.chat.messageStatus === 'sending';
export const checkIsMessageStatusFailure = (state: AppState): boolean => state.chat.messageStatus === 'failure';
export const checkIsMessageStatusIdle = (state: AppState): boolean => state.chat.messageStatus === 'idle';

export const getMessageTextToFill = (state: AppState): ChatState['messageTextToFill'] => state.chat.messageTextToFill;
export const getChatClientSocketID = (state: AppState): ChatState['clientSocketID'] => state.chat.clientSocketID;
export const getDriversGroups = (state: AppState): ChatState['driversGroups'] => state.chat.driversGroups;
export const getPagination = (state: AppState): ChatState['pagination'] => state.chat.pagination;
export const getMessageStatus = (state: AppState): ChatState['messageStatus'] => state.chat.messageStatus;
export const getSearchParams = (state: AppState): ChatState['searchParams'] => state.chat.searchParams;
export const getChatDispatcher = (state: AppState): ChatState['dispatcher'] => state.chat.dispatcher;
export const getUnreadInfo = (state: AppState): ChatState['unreadInfo'] => state.chat.unreadInfo;
export const checkIsChatOpen = (state: AppState): ChatState['isOpen'] => state.chat.isOpen;
export const getServerID = (state: AppState): ChatState['serverID'] => state.chat.serverID;
export const getUnreadMessagesCount = (state: AppState): ChatState['unreadMessagesCount'] =>
    state.chat.unreadMessagesCount;
export const selectMissingTruckNumbers = (state: AppState): ChatState['missingTruckNumbers'] =>
    state.chat.missingTruckNumbers;

export const getAttachmentsToMessage = (state: AppState): ChatState['openedDriversGroup']['attachmentsToMessage'] =>
    state.chat.openedDriversGroup?.attachmentsToMessage;
export const getOpenedTruck = (state: AppState): ChatState['openedDriversGroup']['truck'] =>
    state.chat.openedDriversGroup?.truck;
export const getOpenedDriversGroup = (state: AppState): ChatState['openedDriversGroup'] =>
    state.chat.openedDriversGroup;
export const checkIsAttachmentsUploading = (state: AppState): boolean =>
    state.chat.openedDriversGroup?.isAttachmentsUploading;
export const getDriversGroupUnreadMessages = (state: AppState): number[] =>
    state.chat.openedDriversGroup?.unreadMessages;
