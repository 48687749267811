import filter from 'lodash/filter';
import find from 'lodash/find';
import flatMap from 'lodash/flatMap';
import isEmpty from 'lodash/isEmpty';
import last from 'lodash/last';
import map from 'lodash/map';
import omit from 'lodash/omit';
import toNumber from 'lodash/toNumber';

import {ChatState} from 'widgets/Chat/redux/reducers';
import {DriverSearchFormValues} from 'widgets/Chat/types/form';
import {DriversGroup, MissingTruckNumber} from 'widgets/Chat/types/group';
import {getTruckNumberFromDriver} from 'widgets/Chat/utils';

import {sortGroups} from '../common';

export const getDriverFromDriversGroupsByTruckNumber = (params: {state: ChatState; truckNumber: string}) => {
    const {state, truckNumber} = params;

    const allDrivers = flatMap(state?.driversGroups?.byCognitoUserID, 'drivers');

    return find(allDrivers, (item) => getTruckNumberFromDriver(item, state.serverID) === truckNumber);
};

export const handleReducerByDriversGroupsReceived = (params: {state: ChatState; driversGroups: DriversGroup[]}) => {
    const {state, driversGroups} = params;

    const sortedGroups = sortGroups({driversGroups, unreadInfo: state.unreadInfo || {}});

    return {
        ...state,
        driversGroups: {
            allCognitoUserIDs: sortedGroups.orderedCognitoIDs,
            byCognitoUserID: sortedGroups.byCognitoUserID,
        },
    };
};

export const handleReducerByMoreDriversGroupsReceived = (params: {state: ChatState; driversGroups: DriversGroup[]}) => {
    const {state, driversGroups} = params;

    const oldGroups = Object.values(state.driversGroups.byCognitoUserID);

    const sortedGroups = sortGroups({
        driversGroups: [...oldGroups, ...driversGroups],
        unreadInfo: state.unreadInfo || {},
    });

    return {
        ...state,
        driversGroups: {
            allCognitoUserIDs: sortedGroups.orderedCognitoIDs,
            byCognitoUserID: sortedGroups.byCognitoUserID,
        },
    };
};

export const handleReducerByLeaveDriversGroup = (params: {state: ChatState; cognitoUserID: string}) => {
    const {state, cognitoUserID} = params;

    const {driversGroups, unreadInfo} = state || {};

    const lastGroupMessage = last(driversGroups.byCognitoUserID[cognitoUserID].messages);

    const {orderedCognitoIDs} = sortGroups({
        driversGroups: Object.values(driversGroups.byCognitoUserID),
        unreadInfo: unreadInfo || {},
    });

    return {
        ...state,
        driversGroups: {
            allCognitoUserIDs: orderedCognitoIDs,
            byCognitoUserID: {
                ...state.driversGroups.byCognitoUserID,
                [cognitoUserID]: {
                    ...state.driversGroups.byCognitoUserID[cognitoUserID],
                    messages: lastGroupMessage ? [lastGroupMessage] : [],
                },
            },
        },
        openedDriversGroup: null,
        messageTextToFill: null,
    };
};

export const handleReducerByOpenDriversGroupWithMessage = (params: {
    state;
    truckNumber: string;
    messageText: string;
}) => {
    const {state, truckNumber, messageText} = params;

    const driver = getDriverFromDriversGroupsByTruckNumber({state, truckNumber});

    if (!driver) {
        return {
            ...state,
        };
    }

    return {
        ...state,
        openedDriversGroup: {...state.openedDriversGroup, cognitoUserID: driver.cognitoUserId},
        messageTextToFill: messageText,
        isOpen: true,
    };
};

export const handleReducerBySearchParamsReceived = (params: {
    state: ChatState;
    formValues: Partial<DriverSearchFormValues>;
}) => {
    const {state, formValues} = params;

    if (!formValues.truckNumber) {
        return {
            ...state,
            driversGroups: {
                ...state.driversGroups,
                searchedCognitoUserIDs: null,
            },
            searchParams: null,
        };
    }

    const allDrivers = flatMap(state?.driversGroups?.byCognitoUserID, 'drivers');

    const filteredDrivers = filter(allDrivers, (item) => {
        const driverTruckNumber = getTruckNumberFromDriver(item, state.serverID);

        return driverTruckNumber === formValues.truckNumber;
    });

    const searchedCognitoUserIDs = map(filteredDrivers, (item) => item.cognitoUserId);

    return {
        ...state,
        driversGroups: {
            ...state.driversGroups,
            searchedCognitoUserIDs,
        },
        searchParams: {truckNumber: formValues.truckNumber},
    };
};

export const handleReducerByDriversGroupAllMessagesReceived = (params: {state: ChatState; messages}) => {
    const {state, messages} = params;

    const openedGroup = state.openedDriversGroup.cognitoUserID;

    return {
        ...state,
        driversGroups: {
            ...state.driversGroups,
            byCognitoUserID: {
                ...state.driversGroups.byCognitoUserID,
                [openedGroup]: {
                    ...state.driversGroups.byCognitoUserID[openedGroup],
                    messages,
                },
            },
        },
    };
};

export const handleReducerByMarkMessagesAsReadByEveryDispatcher = (params: {
    state: ChatState;
    groupID: number | string;
}) => {
    const {state, groupID} = params;

    const newUnreadInfo = omit(state.unreadInfo, `${groupID}`);

    const {orderedCognitoIDs} = sortGroups({
        driversGroups: Object.values(state.driversGroups.byCognitoUserID),
        unreadInfo: newUnreadInfo,
    });

    return {
        ...state,
        driversGroups: {
            ...state.driversGroups,
            allCognitoUserIDs: orderedCognitoIDs,
        },
        unreadMessagesCount:
            toNumber(state.unreadMessagesCount || 0) -
            (state.unreadInfo?.[groupID]?.unreadMessagesByDispatcher || []).length,
        openedDriversGroup: {
            ...state.openedDriversGroup,
            unreadMessages: [],
        },
        unreadInfo: newUnreadInfo,
    };
};

export const handleReducerByMarkMessagesAsReadByDispatcher = (params: {state: ChatState; groupID: number}) => {
    const {state, groupID} = params;

    return {
        ...state,
        unreadMessagesCount:
            Number(state.unreadMessagesCount || 0) - (state.openedDriversGroup?.unreadMessages || []).length,
        unreadInfo: {
            ...state.unreadInfo,
            [groupID]: undefined,
        },
    };
};

export const handleReducerByMissingTruckNumbersReceived = (params: {
    payload: MissingTruckNumber[];
    state: ChatState;
}) => {
    const {state, payload} = params;

    if (isEmpty(payload)) {
        return state;
    }

    const missingTruckNumbers = {};

    payload.forEach((item) => {
        missingTruckNumbers[item.truckNumber] = item.groupID;
    });

    return {
        ...state,
        missingTruckNumbers: {...(state.missingTruckNumbers || {}), ...missingTruckNumbers},
    };
};

export const handleReducerByOnlyPaginationTotalReceived = (params: {payload: {total: number}; state: ChatState}) => {
    const {state, payload} = params;

    const {total} = payload || {};

    if (state.pagination.total > 0) {
        return state;
    }

    return {
        ...state,
        pagination: {...(state.pagination || {}), total: total || 0},
    };
};
