import React from 'react';
import {Field, InjectedFormProps, reduxForm} from 'redux-form';

import {DRIVER_SEARCH_FORM} from 'widgets/Chat/constants';
import {DriverSearchFormValues} from 'widgets/Chat/types/form';

import {normalizeNumberLetterHyphenInput} from 'utils';
import {getTypeFieldNameFactory} from 'utils/typeScript';

import DriverSearchInput from './components/DriverSearchInput';

import styles from './styles.module.scss';

const getName = getTypeFieldNameFactory<DriverSearchFormValues>();

type OwnProps = {
    searching: boolean;
};

const DriverSearchForm: React.FC<OwnProps & InjectedFormProps<{}, OwnProps>> = (props) => {
    const {handleSubmit, searching} = props;

    return (
        <form className={styles.wrap} onSubmit={handleSubmit}>
            <Field
                normalize={normalizeNumberLetterHyphenInput}
                component={DriverSearchInput}
                name={getName('truckNumber')}
                searching={searching}
                placeholder="truck#"
                maxLength={8}
            />
        </form>
    );
};

export default reduxForm<{}, OwnProps>({form: DRIVER_SEARCH_FORM, enableReinitialize: true})(DriverSearchForm);
