import ChatWarnAlert from './ChatWarnAlert';
import SuccessfulAddedFiles from './SuccessfulAddedFiles';
import SuccessfulMergedCustomers from './SuccessfulMergedCustomers';
import TruckHasExpiredDocumentsError from './TruckHasExpiredDocumentsError';
import UnsuccessfulAddedFiles from './UnsuccessfulAddedFiles';
import UnsuccessfulMergedCustomers from './UnsuccessfulMergedCustomers';

const notifications = {
    truckHasExpiredDocumentsError: TruckHasExpiredDocumentsError,
    customersUnsuccessfulMerged: UnsuccessfulMergedCustomers,
    customersSuccessfulMerged: SuccessfulMergedCustomers,
    filesUnsuccessfulAdded: UnsuccessfulAddedFiles,
    filesSuccessfulAdded: SuccessfulAddedFiles,
    chatWarnAlert: ChatWarnAlert,
};

export default notifications;
