import isArray from 'lodash/isArray';
import isEmpty from 'lodash/isEmpty';
import keyBy from 'lodash/keyBy';
import orderBy from 'lodash/orderBy';
import uniqBy from 'lodash/uniqBy';

import {ChatState} from 'widgets/Chat/redux/reducers';
import {UnreadInfo} from 'widgets/Chat/types';
import {DriversGroup} from 'widgets/Chat/types/group';

const orderByMessageDate = (groups: DriversGroup[]) => {
    const groupsWithOrderedMessages = groups.map((group) => {
        const orderedMessages = orderBy(group.messages || [], [(message) => message.createdAt || ''], ['desc']);
        return {...group, messages: orderedMessages};
    });

    return orderBy(groupsWithOrderedMessages, [(group) => group.messages[0]?.createdAt || ''], ['desc']);
};

export const sortGroups = (params) => {
    const {unreadInfo, driversGroups} = params;

    const groupWithUnreadMessages: any = [];
    const groupWithReadMessages: any = [];

    const filteredDriversGroups = (driversGroups || []).filter((driversGroup) =>
        Boolean(driversGroup?.drivers?.[0]?.cognitoUserId),
    );

    const uniqDriversGroups = uniqBy(filteredDriversGroups, 'id');

    uniqDriversGroups.forEach((group) => {
        const {id} = (group as any) || {};

        if (id && id in unreadInfo && !isEmpty(unreadInfo[id] || [])) {
            groupWithUnreadMessages.push(group);
        } else {
            groupWithReadMessages.push(group);
        }
    });

    const orderedDriversGroups = [
        ...orderByMessageDate(groupWithUnreadMessages),
        ...orderByMessageDate(groupWithReadMessages),
    ];

    return {
        orderedCognitoIDs: orderedDriversGroups.map((group) => group?.drivers?.[0]?.cognitoUserId),
        byCognitoUserID: keyBy(orderedDriversGroups, (group) => group.drivers[0].cognitoUserId),
        orderedGroups: orderedDriversGroups,
    };
};

export const mergeGroups = (params: {serverID: string; tmsDrivers; groups}) => {
    const {groups, tmsDrivers, serverID} = params;

    const mappedGroups = groups.map((group) => {
        const mappedDrivers = group.drivers.map((driver) => {
            const currentTmsDriver = tmsDrivers.find((tmsDriver) => tmsDriver?.cognitoUserId === driver?.cognitoUserId);

            const additionalData = {
                tmsUserID: currentTmsDriver?.user_id,
                language: currentTmsDriver?.language,
                tmsEntityID: currentTmsDriver?.id,
                serverID,
            };

            if (currentTmsDriver) {
                return {
                    avatars: [{thumb: currentTmsDriver?.avatar?.thumb, serverID}],
                    trucks: [{number: currentTmsDriver?.truck?.number, serverID}],
                    cognitoUserId: currentTmsDriver?.cognitoUserId,
                    serverIDs: currentTmsDriver?.serverIds || [],
                    mobilePhone: currentTmsDriver?.mobile_phone,
                    isDeleted: currentTmsDriver?.isDeleted,
                    isOwner: currentTmsDriver?.is_owner,
                    name: currentTmsDriver?.full_name,
                    additionalData: [additionalData],
                    isMain: currentTmsDriver?.isMain,
                    type: driver.type,
                    id: driver.id,
                };
            }

            return driver;
        });

        return {...group, drivers: mappedDrivers};
    });

    return mappedGroups.filter((group) => {
        const trucks = (group?.drivers || []).map((driver) => driver.trucks).flat();
        const isTruckExist = (trucks || []).find((truck) => Boolean(truck?.number) && truck.serverID === serverID);

        const [driver] = group.drivers || [];

        return isTruckExist && Boolean(driver?.isMain) && !driver?.isDeleted;
    });
};

export const sortMessages = (params: {messages}) => {
    const {messages} = params;

    if (isEmpty(messages) && !isArray(messages)) {
        return [];
    }

    const uniqMessages = uniqBy(messages, 'id');

    return orderBy(uniqMessages, [(message: any) => message?.createdAt || ''], ['asc']);
};

export const handleReducerByUnreadInfoReceived = (params: {state: ChatState; unreadInfo: UnreadInfo[]}) => {
    const {state, unreadInfo} = params;

    const totalUnreadMessages = unreadInfo.reduce(
        (acc, value) => acc + (value?.unreadMessagesByDispatcher || []).length,
        0,
    );

    return {
        ...state,
        unreadInfo: keyBy(unreadInfo, 'groupID'),
        unreadMessagesCount: totalUnreadMessages,
    };
};
