import React, {useEffect} from 'react';
import {EditorContent, useEditor} from '@tiptap/react';
import last from 'lodash/last';

import {SEND_MESSAGE_FORM} from 'widgets/Chat/constants';
import useChatState from 'widgets/Chat/hooks/useChatState';

import useResetReduxForm from 'hooks/reduxForm/useResetReduxForm';

import {extensions} from './utils';

import styles from './styles.module.scss';

type OwnProps = {
    setMessageText: (message: string) => void;

    isEditorDisabled: boolean;
    onSubmitByEnter: any;
    messageText: string;
};

const TipTapEditor: React.FC<OwnProps> = (props) => {
    const {messageText, setMessageText, onSubmitByEnter, isEditorDisabled} = props;

    const resetForm = useResetReduxForm(SEND_MESSAGE_FORM);
    const {
        isMessageStatusSuccess,
        isMessageStatusFailure,
        openedDriversGroup,
        chatMessageStatus,
        messageTextToFill,
        chatDispatcher,
        driversGroups,
    } = useChatState();

    const editor = useEditor({
        extensions,
        content: messageText || messageTextToFill,
        editorProps: {
            attributes: {class: styles.editor},
            handleDOMEvents: {
                keydown: (view, event) => {
                    const isOnlyEnter =
                        !event.ctrlKey && !event.shiftKey && (event.code === 'Enter' || event.code === 'NumpadEnter');

                    if (isOnlyEnter) {
                        onSubmitByEnter();
                    }

                    return false;
                },
            },
        },
        onUpdate: (event) => {
            const message = event.editor.getText().trim() ? event.editor.getText() : '';

            setMessageText(message);
        },
    });

    useEffect(() => {
        editor?.commands.focus('end');
    }, [editor]);

    useEffect(() => {
        editor?.commands.setContent(messageTextToFill);
        editor?.commands.focus('end');
    }, [messageTextToFill]);

    useEffect(() => {
        const {messages} = driversGroups.byCognitoUserID[openedDriversGroup.cognitoUserID];

        const lastMessage = last(messages);

        if (isMessageStatusSuccess && lastMessage?.user.id === chatDispatcher?.id) {
            editor?.commands.clearContent();
            editor?.setEditable(true);
            editor?.commands.focus();
            resetForm();
        }

        if (isMessageStatusFailure) {
            editor?.setEditable(true);
            editor?.commands.focus();
        }
    }, [chatMessageStatus]);

    useEffect(() => {
        editor?.setEditable(!isEditorDisabled);
    }, [isEditorDisabled]);

    return <EditorContent editor={editor} />;
};

export default TipTapEditor;
