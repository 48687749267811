import {checkDriverLicenseExpiration} from 'core/entities/Driver/modules/driverLicense/checkDriverLicenseExpiration';
import Driver from 'core/entities/Driver/types';
import Truck from 'core/entities/Truck/types';

export const getFirstTruckDriver = (truck?: Truck | null): Driver | undefined =>
    truck?.drivers?.find((driver) => driver?.is_main);

export const getSecondTruckDriver = (truck?: Truck | null): Driver | undefined =>
    truck?.drivers?.find((driver) => !driver?.is_main);

export const getOwnerTruckDriver = (truck?: Truck | null): Driver | undefined =>
    truck?.drivers?.find((driver) => driver?.is_owner);

type TruckDrivers = {
    firstDriver: ReturnType<typeof getFirstTruckDriver>;
    secondDriver: ReturnType<typeof getSecondTruckDriver>;
};

export const getTruckDrivers = (truck?: Truck | null): TruckDrivers | null => {
    if (!truck?.drivers) {
        return null;
    }

    const {drivers} = truck;

    // TEMP SOLUTION, after archive truck API clear drivers data
    // so we cant use anymore is_main prop for calculate first|second driver
    if (truck.is_deleted) {
        const [firstDriver, secondDriver] = drivers;

        return {firstDriver, secondDriver};
    }

    return {
        firstDriver: getFirstTruckDriver(truck),
        secondDriver: getSecondTruckDriver(truck),
    };
};

export const checkTruckDriversLicensesExpiration = (truck?: Truck | null) => {
    const {firstDriver, secondDriver} = getTruckDrivers(truck) || {};

    return {
        firstDriver: checkDriverLicenseExpiration(firstDriver?.expiration),
        secondDriver: checkDriverLicenseExpiration(secondDriver?.expiration),
    };
};
