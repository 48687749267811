import React, {useEffect, useState} from 'react';

import ChatAlert from './components/ChatAlert';
import DriverSearchForm from './components/DriverSearchForm';
import DriversGroupExpanded from './components/DriversGroupExpanded';
import DriversGroups from './components/DriversGroups';
import LoadMore from './components/LoadMore';
import useChatActions from './hooks/useChatActions';
import useChatState from './hooks/useChatState';
import {DriverSearchFormValues} from './types/form';

import styles from './styles.module.scss';

const Chat = () => {
    const {initializeChat, searchDriverByTruckNumber, receiveDriversGroups} = useChatActions();
    const {isChatOpen, searchParams} = useChatState();
    const [searching, setSearching] = useState(false);

    useEffect(() => {
        initializeChat();
    }, []);

    useEffect(() => {
        if (isChatOpen) {
            receiveDriversGroups();
        }
    }, [isChatOpen]);

    if (!isChatOpen) {
        return null;
    }

    const startSearching = () => {
        setSearching(true);
    };

    const endSearching = () => {
        setSearching(false);
    };

    const handleSubmit = (formValues: Partial<DriverSearchFormValues>) => {
        if (!searching) {
            startSearching();
            searchDriverByTruckNumber({formValues, endSearching});
        }
    };

    return (
        <div className={styles.wrap}>
            <DriversGroupExpanded />

            <div className={styles.title}>Driver’s Chat</div>

            <ChatAlert />

            <DriverSearchForm
                initialValues={{truckNumber: searchParams?.truckNumber}}
                onSubmit={handleSubmit}
                searching={searching}
            />

            <DriversGroups />

            <LoadMore />
        </div>
    );
};

export default Chat;
