import {InferActions} from 'store';

import Customer from 'core/entities/Customer/types';

import {archiveCustomerActionCreators} from 'pages/Customers/redux/actions/archiving';
import {customersActionCreators} from 'pages/Customers/redux/actions/list';
import * as actionArchivingTypes from 'pages/Customers/redux/actionTypes/archiving';
import * as actionListTypes from 'pages/Customers/redux/actionTypes/list';

type ActionTypes = InferActions<typeof customersActionCreators | typeof archiveCustomerActionCreators>;

export type CustomersState = {
    searchParams: any;
    items: Customer[];
    pagination: {
        perPage: number;
        pagesCount: number;
        currentPage: number;
        totalItemsCount: number;
    };
};

const defaultState: CustomersState = {
    searchParams: null,
    items: [],
    pagination: {
        perPage: 50,
        pagesCount: 1,
        currentPage: 1,
        totalItemsCount: 50,
    },
};

const list = (state = defaultState, action: ActionTypes): CustomersState => {
    switch (action.type) {
        case actionListTypes.RECEIVED_CUSTOMERS: {
            const {customers, pagination} = action.payload;
            return {
                ...state,
                pagination,
                items: customers,
            };
        }

        case actionArchivingTypes.SET_ARCHIVED_CUSTOMER: {
            const {customer: archivedCustomer} = action.payload;
            return {
                ...state,
                items: state.items.filter((customer) => customer.id !== archivedCustomer.id),
            };
        }

        case actionListTypes.SET_CUSTOMERS_SEARCH_PARAMS: {
            return {
                ...state,
                searchParams: action.payload,
            };
        }

        case actionListTypes.SET_CUSTOMERS_CURRENT_PAGE: {
            return {
                ...state,
                pagination: {
                    ...state.pagination,
                    currentPage: action.payload,
                },
            };
        }

        case actionListTypes.SET_CUSTOMERS_PER_PAGE: {
            return {
                ...state,
                pagination: {
                    ...state.pagination,
                    perPage: action.payload,
                },
            };
        }

        case actionListTypes.CLEAR_CUSTOMERS_STATE:
            return defaultState;

        // no default
    }

    const _exhaustiveCheck = action;

    return state;
};

export default list;
