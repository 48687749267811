import Carrier from 'core/entities/Carrier/types';
import Owner from 'core/entities/Owner/types';
import Truck from 'core/entities/Truck/types';

import {GroupedFiles} from 'types/File';

export type Citizenship = 'citizen' | 'resident' | 'nl';

type Benefit = {
    description: string;
    title: string;
    link: string;
    logo: string;
    id: string;
};

export type Networks = {
    benefits: Benefit[];
    carriers: Carrier[];
    name: string;
    id: string;
};

interface AnnualRpm {
    rpm_eur: string;
    rpm_gbr: string;
    rpm_pln: string;
    rpm_usd: string;
}

export interface DriverIdentityDocuments {
    expiration_date: string | null;
    issue_date: string | null;
    identity_number: string | null;
    visa_type: string | null;
    type: string | null;
    id: string;
}

interface Driver {
    id: number;
    truck?: Truck;
    hired_by?: any;
    user?: any;
    owner?: Owner;
    full_name: string;
    date_of_birthday?: string;
    country_of_birth: string;
    language: string;
    work_permit_expiry_date?: string;
    hired_date: string;
    canada: boolean;
    mexico: boolean;
    great_britain: boolean;
    switzerland: boolean;
    licence_type?: string;
    licence_number?: string;
    licence_state?: string;
    licence_country?: string;
    citizenship: Citizenship;
    driving_since?: string;
    expiration?: string;
    hazmat_certificate: boolean;
    hazmat_since?: string;
    hazmat_exp?: string;
    tsa_certificate: boolean;
    class?: string;
    tsa_exp?: string;
    twic_certificate: boolean;
    twic_since?: string;
    twic_exp?: string;
    tanker_endorsement: boolean;
    tsa_since?: string;
    address_line: string;
    additional_address_line?: string;
    city: string;
    state: string;
    zip: string;
    note?: string;
    mobile_phone: string;
    additional_mobile_phone?: string;
    home_phone?: string;
    email?: string;
    country: string;
    relationship?: string | null;
    ec_phone?: string;
    status?: string;
    mobile_app: boolean;
    annual_rpm: AnnualRpm;
    gps_on: boolean;

    device_type?: string;
    device_info?: string;
    device_id?: string;
    device_app_version?: string;
    driver_note?: string;
    driver_note_updated_at?: string;
    home_lat?: string;
    home_lng?: string;
    ec_name?: string;
    not_working_with?: string;
    fax?: string;
    ec_phone2?: string;
    is_main: boolean;
    is_owner: boolean;
    is_deleted: boolean;
    files?: GroupedFiles;
    generalNotes?: any[];
    drivers_documents: DriverIdentityDocuments[] | null;
    networks: Networks[] | null;
    networksId: string[] | null;

    adr_certificate: boolean;
    adr_certificate_number?: string;
    adr_class?: string;
    adr_country?: string;
    adr_expire?: string;

    loi_macron_certificate: boolean;
    loi_macron_expire?: string;
    loi_macron_issue?: string;

    mi_log_certificate: boolean;
    mi_log_expire?: string;
    mi_log_issue?: string;

    disabled: boolean;
    disabled_at: string;
    hasCommonAccount?: boolean;
}

export const getEntityFieldName = (name: keyof Driver): string => name;

export default Driver;
