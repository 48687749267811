import io from 'socket.io-client';

import appConfig from 'config';

const getWSUrl = (isDev) => (isDev ? `ws://localhost:3003` : `${window.location.hostname}`);
const checkIsDev = () => appConfig.NODE_ENV === 'development';

export const chatWSConnectRequest = (): Promise<WebSocket> => {
    const isDev = checkIsDev();
    const wsUrl = getWSUrl(isDev);

    return new Promise((resolve, reject) => {
        const socket = io(wsUrl, {
            path: '/api/chat/v2/socket.io',
            transports: ['websocket'],
            reconnection: true,
        });

        socket.on('connect', () => {
            console.info(`client has connected to the chat, socket.id = ${socket.id}`);
            resolve(socket);
        });

        socket.on('connect_error', (error) => {
            console.info('client has not connected to the chat');
            reject(error);
        });
    });
};
