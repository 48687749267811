import React, {useState} from 'react';
import classNames from 'classnames';
import isEmpty from 'lodash/isEmpty';

import useChatActions from 'widgets/Chat/hooks/useChatActions';
import useChatState from 'widgets/Chat/hooks/useChatState';

import styles from './styles.module.scss';

const LoadMore: React.FC = () => {
    const {openedDriversGroup, driversGroups, pagination} = useChatState();
    const [loading, setLoading] = useState(false);
    const actions = useChatActions();

    const {allCognitoUserIDs} = driversGroups;

    const totalPages = Math.ceil(pagination?.total / pagination?.perPage);

    if (pagination?.page >= totalPages || pagination?.total === 0) {
        return null;
    }

    if (isEmpty(allCognitoUserIDs) || openedDriversGroup?.cognitoUserID) {
        return null;
    }

    const showLoader = () => {
        setLoading(true);
    };

    const hideLoader = () => {
        setLoading(false);
    };

    const onClick = () => {
        if (!loading) {
            showLoader();
            actions.loadMoreGroups({hideLoader});
        }
    };

    return (
        <div className={classNames(styles.wrap, {[styles.disable]: loading})} onClick={onClick}>
            load more
            <i className={classNames('ml5 fa fa-refresh', {'fa-spin': loading})} />
        </div>
    );
};

export default LoadMore;
