import React, {useEffect, useState} from 'react';
import classNames from 'classnames';

import ChatAlert from 'widgets/Chat/components/ChatAlert';
import DriverAvatar from 'widgets/Chat/components/DriverAvatar';
import MessageForm from 'widgets/Chat/components/MessageForm';
import useChatActions from 'widgets/Chat/hooks/useChatActions';
import useChatState from 'widgets/Chat/hooks/useChatState';
import {getDriverLanguage, getDriverTmsEntityID} from 'widgets/Chat/utils';

import LanguageBadge from 'components/ui/Badges/LanguageBadge';
import Button from 'components/ui/Buttons/Button';
import {DriverLink, TruckLink} from 'components/ui/Links';
import Tooltip from 'components/ui/Tooltip';

import {phoneNumberWithBraces} from 'utils';

import CurrentTravelOrder from './components/CurrentTravelOrder';
import ListMessages from './components/ListMessages';

import styles from './styles.module.scss';

const DriversGroupExpanded: React.FC = () => {
    const {openedDriversGroup, driversGroups, truck, serverID} = useChatState();
    const actions = useChatActions();

    const [isMarkMessagesLoading, setIsMarkMessagesLoading] = useState(false);
    const [isDriversGroupMessagesLoading, setIsDriversGroupMessagesLoading] = useState(false);

    const {cognitoUserID} = openedDriversGroup || {};

    useEffect(() => {
        if (cognitoUserID) {
            actions.getTruckForDriversGroup({cognitoUserID});
            actions.getDriversGroupInitLatestMessages({cognitoUserID});
        }
    }, [cognitoUserID]);

    if (!cognitoUserID) {
        return null;
    }

    const driversGroup = driversGroups.byCognitoUserID[openedDriversGroup.cognitoUserID];

    const [driver] = driversGroup.drivers || [];

    const driverTmsEntityID = getDriverTmsEntityID(driver, serverID);
    const driverLanguage = getDriverLanguage(driver, serverID);

    const driverTitle = `${driver.isOwner ? '(o/d)' : '(1dr)'} ${driver.name}`;
    const driverToLink = {...driver, id: driverTmsEntityID};

    const isMainDriver = truck?.mainContact?.id === driverTmsEntityID;

    const startMarkMessages = () => {
        setIsMarkMessagesLoading(true);
    };
    const finishMarkMessages = () => {
        setIsMarkMessagesLoading(false);
    };
    const startLoadingDriversGroupMessages = () => {
        setIsDriversGroupMessagesLoading(true);
    };
    const finishLoadingDriversGroupMessages = () => {
        setIsDriversGroupMessagesLoading(false);
    };
    const handleGetDriversGroupAllMessages = () => {
        startLoadingDriversGroupMessages();
        actions.getDriversGroupAllMessages({cognitoUserID, onFinish: finishLoadingDriversGroupMessages});
    };

    const handleMarkMessagesAsReadByEveryDispatcher = () => {
        startMarkMessages();
        actions.markMessagesAsReadByEveryDispatcher({groupID: driversGroup.id, onFinish: finishMarkMessages});
    };

    const handleLeaveDriversGroup = () => {
        actions.leaveDriversGroup({cognitoUserID});
    };

    const markMessagesBtnIcon = isMarkMessagesLoading ? <i className="fa fa-spinner fa-pulse ml5" /> : 'check';
    const loadDriversGroupMessagesBtnIcon = isDriversGroupMessagesLoading ? (
        <i className="fa fa-spinner fa-pulse ml5" />
    ) : (
        'refresh'
    );

    return (
        <div className={styles.room}>
            <div className={styles.back} onClick={handleLeaveDriversGroup}>
                <i className="fa fa-long-arrow-left" aria-hidden="true" /> Back to the list
            </div>

            <ChatAlert />

            <div className={styles.driver}>
                <div className="w-100 d-flex align-items-end justify-content-between">
                    <div className={styles.left}>
                        <DriverAvatar driver={driver} />

                        <div className="ml20" />

                        <div>
                            <div className={classNames(styles.title, 'ellips-text')}>
                                <DriverLink content={driverTitle} driver={driverToLink} className="second-link" />
                            </div>

                            <div className={classNames(styles.phone, {[styles.main]: isMainDriver})}>
                                {phoneNumberWithBraces(driver.mobilePhone)}
                            </div>

                            <div className={styles.truck}>
                                {truck ? <TruckLink content={`Truck #${truck.number}`} truck={truck} /> : null}

                                <CurrentTravelOrder />
                            </div>
                        </div>
                    </div>

                    <div className="d-flex">
                        <Tooltip tooltipContent="mark as read for all users" position="right" typeContent="button">
                            <Button
                                onClick={handleMarkMessagesAsReadByEveryDispatcher}
                                buttonIcon={markMessagesBtnIcon}
                                disabled={isMarkMessagesLoading}
                                buttonSize="icon"
                            />
                        </Tooltip>

                        <Tooltip
                            tooltipContent="load all messages, use CTRL + F to search"
                            typeContent="button"
                            position="right"
                        >
                            <Button
                                buttonSize="icon"
                                onClick={handleGetDriversGroupAllMessages}
                                buttonIcon={loadDriversGroupMessagesBtnIcon}
                                disabled={isDriversGroupMessagesLoading}
                            />
                        </Tooltip>
                    </div>
                </div>

                <div className={styles.badges}>
                    {isMainDriver ? <div className={styles.main}>main</div> : null}

                    <LanguageBadge language={driverLanguage} withBorderRadius={true} isTooltip={false} />
                </div>
            </div>

            <ListMessages />

            <MessageForm />
        </div>
    );
};

export default DriversGroupExpanded;
